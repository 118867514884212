import { Link } from "react-router-dom"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import Image from 'material-ui-image'

const useStyles = makeStyles((theme) => ({
    mainChannelListItemContainer: {
        border: '1px solid rgba(0,0,0,0)',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.04)',
            border: '1px solid rgba(255,255,255,0.05)'
        },
        position: 'relative'
    },
    mainChannelListItemThumbnail: {
        '&:hover': {
            filter: 'brightness(1.1)'
        },
    },
    mainChannelListItemTitle: {
        marginLeft: '8px',
        marginTop: '4px',
        marginBottom: '18px'
    },
    mainChannelListItemPublishDate: {
        marginRight: '8px',
        marginBottom: '4px',
        color: '#aaa',
        position: 'absolute',
        bottom: '0',
        right: 0
    }
}))

const getDate = function(YYYYMMDD) {
    var year = YYYYMMDD.substring(0,4)
    var month = YYYYMMDD.substring(4,6)
    var day = YYYYMMDD.substring(6,8)

    var date = new Date(year, month-1, day)
    return date
}

function MainChannelListItem(props) {
    const title = props.video.title
    const publishDate = getDate(props.video.publishDate).toLocaleDateString('en-US')
    const classes = useStyles()
    const id = props.video.id
    const thumbnail = `//cdn.foodiebeauty.site/ID${id}/${props.video.thumbnails[3].url}`
    return (
        <>
            <Grid item className={classes.mainChannelListItemContainer} xs={12} sm={6} md={4} lg={3}>
                <Link to={`/watch?v=${id}`}>
                    <Image className={classes.mainChannelListItemThumbnail} 
                                                src={thumbnail} 
                                        aspectRatio={16/9} 
                                                color='rgba(0,0,0,0)' 
                                    animationDuration={1000}/>
                    <Typography variant='h6' className={classes.mainChannelListItemTitle}>{title}</Typography>
                    <Typography variant='caption' className={classes.mainChannelListItemPublishDate}>{publishDate}</Typography>
                </Link>
            </Grid>
        </>
    );
}

export default MainChannelListItem