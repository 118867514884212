import CommunityPosts from '../../Assets/community/data.json'
import { Grid } from "@material-ui/core"
import { Helmet } from 'react-helmet'
import CommunityPost from './CommunityPost'

function CommunityPage(props) {
    const posts = CommunityPosts
    var modified = []
    var unknown = []
    posts.map(post => {
        if (post.modified) {
            modified.push(post)
        } else {
            unknown.push(post)
        }
    })
    unknown = unknown.sort((a,b) => { 
        return b.postId - a.postId
    })
    modified = modified.sort((a,b) => { 
        const x = parseInt(a.modified)
        const y = parseInt(b.modified)
        return y - x 
    })
    return (
        <>
            <Helmet>
                <title>Community Page</title>
            </Helmet>
            <Grid container style={{ 
                marginTop: '80px',
                backgroundColor: 'rgb(21, 32, 43)'
            }}>
                {modified.map((post) => {
                    return <CommunityPost key={posts.postId} post={post} known={true}/>
                })}
                {unknown.map((post) => {
                    return <CommunityPost key={posts.postId} post={post} known={false}/>
                })}
            </Grid>
        </>
    );
}

export default CommunityPage