import { isSafari } from "react-device-detect"
import ReactHlsPlayer from 'react-hls-player'
import { makeStyles } from '@material-ui/core/styles'
import { isMobileSafari } from "react-device-detect"

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        width: '100%',
        backgroundColor: 'rgb(21, 32, 43)',
        marginTop: '63px'
    },
    video: {
        width: '100%',
        backgroudColor: 'rgb(21, 32, 43)'
    }
}))
  
function VideoPlayer(props) {
    const classes = useStyles()
    const video = props.video
    return (function(){
        if (isSafari) {
             return <video poster={`//cdn.foodiebeauty.site/ID${video.id}/${video.thumbnails[video.bestThumb].url}`}
             src={`//cdn.foodiebeauty.site/ID${video.id}/_.m3u8`} autoplay={true} className={classes.video}
             controls={true} width="100%" height="auto"/>   
        } else if (isMobileSafari) {
            return <video poster={`//cdn.foodiebeauty.site/ID${video.id}/${video.thumbnails[video.bestThumb].url}`}
             src={`//cdn.foodiebeauty.site/ID${video.id}/_.m3u8`} autoplay={true} className={classes.video}
             controls={true} width="100%" height="auto"/>
        } else {
            return <ReactHlsPlayer poster={`//cdn.foodiebeauty.site/ID${video.id}/${video.thumbnails[video.bestThumb].url}`}
                    key={video.id} url={`//cdn.foodiebeauty.site/ID${video.id}/_.m3u8`} className={classes.video} 
                    autoplay={true} controls={true} width="100%" height="auto"/>
        }
    }())
}

export default VideoPlayer