import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { fade, makeStyles } from '@material-ui/core/styles'
import InstagramIcon from '@material-ui/icons/Instagram'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import KitchenIcon from '@material-ui/icons/Kitchen'
import InputBase from '@material-ui/core/InputBase'
import MoreIcon from '@material-ui/icons/MoreVert'
import SearchIcon from '@material-ui/icons/Search'
import MenuItem from '@material-ui/core/MenuItem'
import MediaQuery from  'react-responsive'
import CloseIcon from '@material-ui/icons/Close'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Badge from '@material-ui/core/Badge'
import Menu from '@material-ui/core/Menu'
import { Router, withRouter } from 'react-router'
import 'fontsource-roboto'
import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  mobileMenu: {
    backgroundColor: '#333'
  },
  title: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  desktopMenuIcon: {
    marginRight: '5px'
  },
  search: {
    display: 'block',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  closeIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: '0',
    top: '0',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px',
    zIndex: '9000',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  sectionMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    color: 'white'
  },
  mobileMenuWrap: {
    paper: {
      backgroundColor: '#333'
    }
  }
}));

function FoodieBeautyArchiveAppBar(props) {

  const [a,s] = React.useState(props.query)
  const setQuery = props.setQuery
  const query = props.query
  const resetSearch = (event) => {
    window.location.href = window.location.href
  }

  const handleChange = (event) => {
    setQuery(event.target.value)
  }

  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClick={handleMobileMenuClose}
      className={classes.mobileMenuWrap}
    >
      <Link to='/community' onClick={handleMobileMenuClose}>
        <MenuItem>
          <IconButton color="inherit">
            <div style={{
                  fontSize: '18px',
                  marginRight: '5px',
                  marginTop: '-2px'
            }}>📰</div>
          </IconButton>
          <Typography variant='body1'>Community</Typography>
        </MenuItem>
      </Link>
      <Link to='/beautybot' onClick={handleMobileMenuClose}>
        <MenuItem>
          <IconButton color="inherit">
            <Badge color="secondary">
              <div style={{
                fontSize: '20px',
                marginTop: '0px'
              }}>🚨</div>
            </Badge>
          </IconButton>
          <Typography variant='body1'>Beauty Bot</Typography>
        </MenuItem>
      </Link>
      {/* <Link to='/instagram' onClick={handleMobileMenuClose}>
      <MenuItem>
        <IconButton color="inherit">
          <Badge color="secondary">
            <InstagramIcon/>
          </Badge>
        </IconButton>
        <Typography variant='body1'>Instagram</Typography>
      </MenuItem>
      </Link>
      <Link to='/lostandfound' onClick={handleMobileMenuClose}>
      <MenuItem >
        <IconButton color="inherit">
          <VisibilityOffIcon/>
        </IconButton>
        <Typography variant='body1'>Lost & Found</Typography>
      </MenuItem>
      </Link> */}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" color='secondary'>
        <Toolbar>
            <MenuItem>
              <Link to='/'>
                <div style={{
                  fontSize: '30px',
                  marginTop: '-2px',
                  marginRight: '10px'
                }}>🍔</div>
              </Link>
              <MediaQuery minDeviceWidth={430}>
                <Link to='/'>
                  <Typography className={classes.title} variant="h6" noWrap style={{ textDecoration: 'none' }}>
                    Foodie Beauty Archive
                  </Typography>
                </Link>
              </MediaQuery>
            </MenuItem>
          <div className={classes.grow} />
          <div className={classes.search} style={{
            display: window.location.pathname == "/" ? 'block' : 'none'
          }}>
            <div className={classes.closeIcon} onClick={resetSearch} style={{
              display: query !== '' ? 'block' : 'none'
            }}>
              <CloseIcon/>
            </div>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
            />
          </div>
          <div className={classes.sectionDesktop}>
            <Link to='/community'>
              <MenuItem color="inherit">
                <div style={{
                  fontSize: '18px',
                  marginRight: '5px',
                  marginTop: '-2px'
                }}>📰</div>
                <Typography variant='body1' style={{ textDecoration: 'none' }}>Community</Typography>
              </MenuItem>
            </Link>
            <Link to='/beautybot'>
              <MenuItem>
                <div style={{
                  fontSize: '18px',
                  marginRight: '5px',
                  marginTop: '-6px'
                }}>🚨</div>
                <Typography variant='body1'>Beauty Bot</Typography>
              </MenuItem>
            </Link>
            {/* <Link to='/instagram'>
              <MenuItem color="inherit">
                <InstagramIcon className={classes.desktopMenuIcon}/>
                <Typography variant='body1' style={{ textDecoration: 'none' }}>Instagram</Typography>
              </MenuItem>
            </Link>
            <Link to='/lostandfound'>
              <MenuItem color="inherit">
                <VisibilityOffIcon className={classes.desktopMenuIcon}/>
                <Typography variant='body1' style={{ textDecoration: 'none' }}>Lost & Found</Typography>
              </MenuItem>
            </Link> */}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  )
}

export default withRouter(FoodieBeautyArchiveAppBar);