import MainChannelListItem from "./MainChannelListItem"
import MainChannelVideos from '../../Utilities/MainChannelData'
import { Grid } from "@material-ui/core"
import { Helmet } from 'react-helmet'
import React, {  useState, useRef, useCallback } from 'react'

function MainChannelListView(props) {

    const [pageNumber, setPageNumber] = useState(0)
    const { 
        videos,
        hasMore,
        loading,
        total
    } = MainChannelVideos(pageNumber, props.query)

    const observer = useRef()
    const ref = useCallback(node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(p => p + 1)
        }
      })
      if (node) observer.current.observe(node)
    }, [loading, hasMore])

    return (
        <>
            <Helmet>
                <title>Foodie Beauty Archive - Main Channel</title>
            </Helmet>
            <Grid container style={{ 
                marginTop: '80px',
                backgroundColor: 'rgb(21, 32, 43)'
            }}>
                {videos.map((video, index) => {
                    if (videos.length === index + 1 && index+1 !== total) {
                        return (
                            <>
                                <MainChannelListItem key={video.id} video={video}/>
                                <div ref={ref}></div>
                            </>
                        )
                    } else {
                        return <MainChannelListItem key={video.id} video={video}/>
                    }
                })}
            </Grid>
        </>
    );
}

export default MainChannelListView