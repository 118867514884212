import { Container, Grid, Typography } from "@material-ui/core"
import ImageComponent from 'material-ui-image';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

function BeautyBotPreview(props) {
    return (
        <>
            <Helmet>
                <title>Beauty Bot</title>
            </Helmet>
            <iframe src="https://xn--r1a.website/s/foodiebeauty" style={{
                width: '100%',
                marginTop: '6px',
                height: '100%',
                border: '0px',
                position: 'fixed',
                left: '0',
                right: '0',
                top: '0',
                bottom: '0',
                backgroundColor: 'rgb(229, 235, 239)'
            }}></iframe>
        </>
    );
}

export default BeautyBotPreview