import { useEffect, useState } from 'react'
import mainChannel from '../Assets/MainChannel.json'

export default function MainChannelVideos(pageNumber, query) {

    const [loading, setLoading] = useState(true)
    const [videos, setVideos] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const pageLength = 20
    const total = mainChannel.length
    var channel = mainChannel
    var filteredChannel = []

    channel.forEach(vid => {
        if (query !== '') {
            var match = false
            function filter(string){
                var filtered = string
                filtered = filtered.toLowerCase()
                filtered = filtered.replace(' ','')
                filtered = filtered.replace(/[^a-zA-Z\d]/g,'')
                return filtered
            }
            if (filter(vid.title).match(filter(query))) match = true
            if (filter(vid.description).match(filter(filter(query)))) match = true
            if (filter(JSON.stringify(vid.tags)).match(filter(query))) match = true
            if (filter(JSON.stringify(vid.categories)).match(filter(query))) match = true
            if (match) {
                filteredChannel.push(vid)
            }
        } else {
            filteredChannel.push(vid)
        }
    })

    useEffect(() => {

        setLoading(true)
        setVideos(prevVideos => {
            var results = []
            filteredChannel.sort((a, b) => { 
		    const x = parseInt(a.sortDate)
		    const y = parseInt(b.sortDate)
		    return y - x 
	    });
            filteredChannel.forEach((vid,index) => {
                if (index >= (pageNumber*pageLength) && index < (pageNumber*pageLength)+pageLength) {
                    results.push(vid)
                }
            })
            return prevVideos.concat(results)
        })
        setHasMore(true)
        setLoading(false)

    }, [pageNumber])

    return { loading, videos, hasMore, total }

}
