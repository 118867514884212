import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import MainChannelListView from './Components/MainChannelList/MainChannelListView'
import MainChannelPlayerView from './Components/MainChannelPlayer/MainChannelPlayerView'
import ScrollToTop from './Utilities/ScrollToTop'
import AppBar from './Components/FoodieBeautyArchiveAppBar'
import React, {  useState } from 'react'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import './App.css'
import CommunityPage from "./Components/CommunityPostView/CommunityPage"
import BeautyBotPreview from "./Components/BeautyBotPreview"
ReactGA.initialize('UA-27843393-1')

function App() {

  const [query, setQuery] = useState('')

  return (
    <>
      <ThemeProvider theme={theme}>
          <Helmet>
              <title>Foodie Beauty Archive</title>
          </Helmet>
        <Router>
          <ScrollToTop/>
          <AppBar query={query} setQuery={setQuery}/>
          <Switch>
            <Route path="/" exact component={() => <MainChannelListView query={query} setQuery={setQuery}/>} />
            {/* <Route path="/lostandfound" exact component={() => <LostAndFoundListView />} /> */}
            <Route path="/community" exact component={() => <CommunityPage />} />
            <Route path="/beautybot" exact component={() => <BeautyBotPreview />} />
            {/* <Route path="/instagram" exact component={() => <InstagramView />} /> */}
            <Route path="/watch" exact component={() => <MainChannelPlayerView query={query} setQuery={setQuery}/>} />
            {/* <Route path="/recovered" exact component={() => <LostAndFoundPlayerView />} /> */}
          </Switch>
      </Router>
    </ThemeProvider>
    </>
  );
}

const theme = createMuiTheme({
  
  typography: {
    h1: {
      color: 'white'
    },
    h2: {
      color: 'white'
    },
    h3: {
      color: 'white'
    },
    h4: {
      color: 'white',
      marginTop: '10px'
    },
    h5: {
      color: 'white'
    },
    h6: {
      color: 'white'
    },
    subtitle1: {
      color: 'white'
    },
    subtitle2: {
      color: 'white'
    },
    body1: {
      color: 'white'
    },
    body2: {
      color: 'white'
    },
    button: {
      color: 'white'
    },
    caption: {
      color: 'white'
    },
    overline: {
      color: 'white'
    }
  },
  palette: {
    primary: {
      main: 'rgb(75, 163, 226)'
    },
    secondary: {
      main: 'rgb(75, 163, 226)'
    },
    third: {
      main: 'rgb(23, 32, 43)'
    }
  }
})

export default App
