import TelegramComments from 'react-telegram-comments'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import mainVideos from '../../Assets/MainChannel'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useLocation } from "react-router-dom"
import MediaQuery from  'react-responsive'
import { Button, Grid, Typography } from "@material-ui/core"
import { Helmet } from 'react-helmet'
import 'fontsource-roboto'
import React from 'react'
import Footer from '../Footer'
import VideoPlayer from '../VideoPlayer';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        width: '100%',
        backgroundColor: 'rgb(21, 32, 43)',
        marginTop: '63px'
    },
    video: {
        width: '100%',
        backgroudColor: 'rgb(21, 32, 43)'
    }
}))

function MainChannelPlayerView() {

    let id = useQuery().get("v")
    
    var video = {}
    mainVideos.forEach(vid => {
        if (vid.id === id) video = vid
    })

    const getDate = function(YYYYMMDD) {
        var year = YYYYMMDD.substring(0,4);
        var month = YYYYMMDD.substring(4,6);
        var day = YYYYMMDD.substring(6,8);
    
        var date = new Date(year, month-1, day);
        return date
    }

    const classes = useStyles()

    return (
        <>
            <Grid container>
                <Helmet>
                    <title>Foodie Beauty Archive - Main Channel</title>
                    <meta property="og:title" content={`${video.title}`}/>
                    <meta property="og:image" content={`${video.thumbnails[video.bestThumb].url}`}/>
                    <meta property="og:url" content={`https://www.foodiebeauty.site/watch?v=${video.id}`}/>
                    <meta property="og:description" content={`${video.description}`} />
                </Helmet>

                <Grid item className={classes.videoContainer} xs={12} sm={12} md={12} lg={12}>

                    <VideoPlayer key={Math.random().toString()} video={video}/>

                    <Grid container style={{
                        backgroundColor: 'rgb(21, 32, 43)'
                    }}>

                        <Grid item xs={10} sm={10} md={10} lg={10}>
                            <div style={{
                                float: 'left'
                            }}>
                                <Link to="/">
                                    <img src="/fb.jpg" alt='logo' style={{ 
                                        width: '64px', 
                                        height: '64px',
                                        borderRadius: '100px',
                                        marginTop: '20px',
                                        marginLeft: '25px'
                                    }}/> 
                                </Link>
                            </div>
                            <div style={{
                                float: 'left',
                                marginLeft: '10px'
                            }}>
                                <Typography variant='h5' style={{ 
                                    color: 'white',
                                    marginTop: '25px', 
                                }}>Foodie Beauty</Typography>
                                <Typography variant='body1' style={{ color: 'rgb(112, 118, 123)' }}>Published: {getDate(video.publishDate).toLocaleDateString('en-US')}</Typography>
                            </div>   
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            <MediaQuery minDeviceWidth={431}>
                                <div style={{ float: 'right' }}>
                                    <div>
                                        <a href={`https://cdn.foodiebeauty.site/${video.downloadLink}`}>
                                            <Button variant='contained' 
                                                        color='primary'
                                                    className="downloadButton"
                                                        style={{
                                                            marginRight: '35px',
                                                            marginTop: '35px'}
                                            }>Download</Button>
                                        </a>
                                    </div>
                                    <div>
                                        <Typography variant='caption' color='primary' style={{
                                            clear: 'both'
                                        }}>(right click, save as)</Typography>
                                    </div>
                                </div>
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={430}>
                                <a href={`https://cdn.foodiebeauty.site/${video.downloadLink}`}>
                                    <CloudDownloadIcon color='primary' 
                                                    style={{
                                                            marginTop: '34px',
                                                            marginRight: '30xpx',
                                                            width: '32px',
                                                            height: '36px'
                                                        }}/>
                                </a>
                            </MediaQuery>
                        </Grid>

                        <Grid item xs={10} sm={12} md={12} lg={12} style={{
                            margin: '30px 30px 0px 30px',
                        }}>
                            <Typography variant='h4' style={{ color: 'white' }}>{video.title}</Typography>
                        </Grid>

                        <Grid item xs={10} sm={12} md={12} lg={12} style={{
                            margin: '30px',
                        }}>
                            { video.description.split("\n").map(line => (
                                <>
                                    <Typography style={{ color: 'white' }}>{line}</Typography>
                                    <p></p>
                                </>
                            )) }
                        </Grid>

                        <Grid item xs={10} sm={12} md={12} lg={12} style={{
                            margin: '0px 30px 30px 30px',
                        }}>
                            <Typography style={{
                                display: video.tags == null ? 'none' : 'block',
                                color: 'white' }} variant='h6'>Tags: </Typography>
                            { video.tags == null ? null : video.tags.map(tag => (
                                <Typography key={`${tag}`} variant='caption' style={{
                                    marginRight: '5px',
                                    color: 'white' }}>{`${tag}`}</Typography>
                            )) }
                        </Grid>

                        <Grid item xs={10} sm={12} md={12} lg={12} style={{
                            margin: '0px 30px 30px 30px',
                        }}>
                            <Typography style={{
                                marginRight: '5px',
                                display: video.categories == null ? 'none' : 'block',
                                color: 'white' }} variant='h6'>Categories: </Typography>
                            { video.categories == null ? null : video.categories.map(category => (
                                <Typography key={`${category}`} variant='caption' style={{ color: 'white' }}>{`${category}`}</Typography>
                            )) }
                        </Grid>

                        <Grid item xs={10} sm={12} md={12} lg={12} style={{
                            margin: '0px 30px 30px 30px',
                            backgroundColor: 'rgb(21, 32, 43)'
                        }}>  
                            <TelegramComments 
                                websiteKey={'YvMf2x3n'} 
                                showColorfulNames={true}
                                isDark={true}/>
                        </Grid>
                    </Grid>    
                </Grid>
            </Grid>
            <Footer/>
        </>
    );
}

export default MainChannelPlayerView