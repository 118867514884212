import { Grid, Typography } from "@material-ui/core"

function Footer() {
  return (
    <>
        <Grid container style={{ 
            backgroundColor: 'white'
        }}>
            <Grid item xs={10} sm={10} md={5} lg={4} style={{ 
                backgroundColor: 'white',
                margin: '10px 30px 30px 30px'
            }}>
                <Typography variant='h6' style={{ 
                    color: 'rgb(23, 32, 43)',
                    margin: '10px 0px 0px 0px'
                 }}>🚨 Beauty Bot 🚨</Typography>
                <Typography variant='body1' style={{ 
                    color: 'rgb(23, 32, 43)',
                    margin: '20px 0px 20px 0px'
                 }}>
                    👉 <a href='https://t.me/s/foodiebeauty' target="_blank" rel="noreferrer">Join our Telegram channel!</a>
                </Typography>
                <Typography variant='body1' style={{ 
                    color: 'rgb(23, 32, 43)',
                    margin: '20px 0px 20px 0px'
                 }}>
                    Push notifications when Foodie Beauty posts a new video. 1 minute checks for new community posts or edits to existing ones. 20 minute checks for Instagram posts (both @foodie_beauty_official and @samandbbj), updated profile pics, Instagram private status changes.
                </Typography>
                
                <Typography variant='body1' style={{ 
                    color: 'rgb(23, 32, 43)',
                    margin: '20px 0px 20px 0px'
                 }}><em>Foodie Beauty Archive </em> takes time to develop and costs money to host, resources I am happy to cover myself. If you would like to help out, you may do so anonymously with cryptocurrency.</Typography>
                <Typography variant='h6' style={{ 
                    color: 'rgb(23, 32, 43)',
                    margin: '10px 0px 0px 0px'
                 }}>Bitcoin:</Typography>
                <Typography variant='body1' style={{ color: 'rgb(23, 32, 43)' }}>3EKrJFcvAooUVhqBhEeepamoaadSHqFbzi</Typography>
                <Typography variant='h6' style={{ 
                    color: 'rgb(23, 32, 43)',
                    margin: '10px 0px 0px 0px'
                 }}>Ethereum:</Typography>
                <Typography variant='body1' style={{ color: 'rgb(23, 32, 43)' }}>0xa26a2cC21Fa18444112b224881c1daC98692fDAa</Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={5} lg={6} style={{ 
                backgroundColor: 'white',
                margin: '10px 30px 30px 30px'
            }}>
                <Typography variant='h6' style={{ 
                    color: 'rgb(23, 32, 43)',
                    margin: '10px 0px 0px 0px'
                 }}>Foodie Beauty Archive</Typography>
                <Typography variant='caption' style={{ 
                    color: 'rgb(23, 32, 43)',
                    margin: '10px 0px 0px 0px'
                 }}>March 16th, 2021</Typography>
                <Typography variant='body1' style={{ color: 'rgb(23, 32, 43)', marginTop: '10px' }}>
	  	Hey guys hey, this is Team Beauty Bot. We've been working on this for quite some time. It started with manual backups of Chantal's content, but we weren't able tosnag every video due to her deleting what are usually the spiciest ones. So, we wrote a script that continuously checks for new videos and downloads them as soon it can.
	  	</Typography>
                <Typography variant='body1' style={{ color: 'rgb(23, 32, 43)', marginTop: '10px' }}>
	  	This automation has led to an enormous hoard of Chantal's content. We have over 1200 of her videos even though there are only around 950 in her channel at time of writing. We figured now would be a good time to put together a proper archive considering she recently unprivated a lot of her videos, meaning we were able to get the rich metadata.
	  	</Typography>
                <Typography variant='body1' style={{ color: 'rgb(23, 32, 43)', marginTop: '10px' }}>
	  	So, we put together this app for you to enjoy her videos and download the backups. There are many additions that will be coming to the archive including a related videos sidebar that relies on the tags and categories, continuous playback using said related videos, Instagram, Community posts, and a new section called "Lost & Found" that will contain her videos that are still deleted to this day.
                </Typography>
            </Grid>
        </Grid>
    </>
  );
}

export default Footer
