import { Container, Grid, Typography } from "@material-ui/core"
import Image from 'material-ui-image'
import { Link } from 'react-router-dom';

function CommunityPost(props) {
    const post = props.post
    const getDate = function(YYYYMMDDHHMM) {
        if (YYYYMMDDHHMM) {
            const postDate = YYYYMMDDHHMM.toString()
            var year = postDate.substring(0,4);
            var month = postDate.substring(4,6);
            var day = postDate.substring(6,8);
        
            var date = new Date(year, month-1, day);
            return date.toLocaleDateString('en-US')
        } else {
            return "~2020"
        }
    }
    return (
        <>
            <Grid item xs={0} sm={1}></Grid>
            <Grid item xs={12} sm={10} style={{
                marginTop: '0px',
                marginBottom: '60px'
            }}>
                <div style={{
                    float: 'left',
                    marginTop: '0px',
                }}>
                    <Link to="/">
                        <img src="/fb.jpg" alt='logo' style={{ 
                            width: '64px', 
                            height: '64px',
                            borderRadius: '100px',
                            marginLeft: '25px'
                        }}/> 
                    </Link>
                </div>
                <div style={{
                    marginLeft: '20px',
                    float: 'left',
                    marginTop: '5px',
                    marginBottom: '20px'
                }}>
                    <Typography variant='h5' style={{ 
                        color: 'white'
                    }}>Foodie Beauty</Typography>
                    <Typography variant='body1' style={{ 
                        color: 'rgb(112, 118, 123)'
                    }}>Posted on: {getDate(post.modified)}{ post.edited ? 
                    ' (edited)'
                    : '' }</Typography>
                </div>
                <div style={{
                        clear: 'both'
                    }}>
                    { post.image ?
                    <img src={`//cdn.foodiebeauty.site/images/${post.image}`} style={{
                        height: 'auto',
                        width: '100%'
                    }}/>
                    : <></> }
                    { post.video ?
                       <div class="container" style={{
                           position: 'relative',
                           width: '100%',
                           height: '0',
                           paddingBottom: '56.25%'
                       }}> 
                        <iframe width="560" 
                               height="315" 
                               src={`https://www.youtube.com/embed/${post.video.split("v=")[1]}`} 
                               frameborder="0" 
                               allow="encrypted-media" 
                               allowfullscreen
                               style={{
                                   width: '100%',
                                   height: '100%',
                                   top: '0',
                                   left: '0',
                                   position: 'absolute'
                               }}></iframe>
                               </div>
                    : <></> }
                    <Typography variant='body1' style={{
                        marginTop: '0px',
                        padding: '20px'
                    }}>{post.text}</Typography>
	    	</div>
            </Grid>
            <Grid item xs={0} sm={1}></Grid>
        </>
    );
}

export default CommunityPost
